export default class ScrollEvent {
  constructor() {
    this.setup();
  }
  setup() {
    const root = document.documentElement;

    let ticking = false;
    let position = 0;
    function onScroll() {
      if (position > 500) {
        root.classList.add('scrolled');
      } else {
        root.classList.remove('scrolled');
      }
    }

    window.addEventListener('scroll', function () {
      position = window.pageYOffset;

      if (!ticking) {
        window.requestAnimationFrame(function () {
          onScroll(position);
          ticking = false;
        });
        ticking = true;
      }
    });
  }
}
