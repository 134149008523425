import MicroModal from 'micromodal';

export default class VideoModal {
  constructor() {
    const trig = document.querySelectorAll('.js-videomodal');
    const iframe = document.getElementById('movie-player');
    for (let i = 0; i < trig.length; i++) {
      trig[i].addEventListener('click', function () {
        const videoid = this.dataset.videoid;
        const modalid = this.dataset.modalid;
        MicroModal.show(modalid, {
          openClass: '-open',
          disableScroll: true,
          disableFocus: true,
          awaitOpenAnimation: true,
          awaitCloseAnimation: true,
          onShow: () => {
            iframe.src = 'https://www.youtube.com/embed/' + videoid + '?enablejsapi=1&autoplay=1&mute=0&rel=0';
            const targetWindow = iframe.contentWindow;
            const ag2ytControl = function (action, arg = null) {
              targetWindow.postMessage('{"event":"command", "func":"' + action + '", "args":' + arg + '}', '*');
            };
            setTimeout(() => {
              ag2ytControl('playVideo');
            }, 500);
          },
          onClose: () => {
            iframe.src = 'about:blank';
          }
        });
      });
    }

    // TOP読み込み時オープン
    const onloadmodalid = document.getElementById('modal-movie-onload');
    if (onloadmodalid) {
      const targetWindow = document.getElementById('onload-player').contentWindow;
      const ag2ytControl = function (action, arg = null) {
        targetWindow.postMessage('{"event":"command", "func":"' + action + '", "args":' + arg + '}', '*');
      };
      window.addEventListener('load', function () {
        if (location.hash == '#kv' || location.hash == '#synopsis') {
          return false;
        } else {
          setTimeout(() => {
            MicroModal.show('modal-movie-onload', {
              openClass: '-open',
              disableScroll: true,
              disableFocus: true,
              awaitOpenAnimation: true,
              awaitCloseAnimation: true,
              onClose: () => {
                ag2ytControl('pauseVideo');
              }
            });
          }, 3500);
        }
      });
    }
  }
}
