import Splide from '@splidejs/splide';

export default class StorySlider {
  constructor() {
    this.setup();
  }
  setup() {
    const elm = document.querySelector('.storySlider');
    if (elm) {
      const splide = new Splide(elm, {
        rewind: true,
        speed: 600,
        arrows: true,
        type: 'fade',
        heightRatio: 0.5622277,
        pagination: false,
        cover: true
      });

      splide.mount();
    }
  }
}
