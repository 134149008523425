export default class Loading {
  constructor() {
    const root = document.documentElement;
    if (location.hash == '#kv' || location.hash == '#synopsis') {
      root.classList.add('stop-loading');
    }
    window.addEventListener('DOMContentLoaded', function () {
      root.classList.add('domloaded');
    });
    window.addEventListener('load', function () {
      root.classList.add('loaded');
    });
  }
}
