import Splide from '@splidejs/splide';
import {URLHash} from '@splidejs/splide-extension-url-hash';

export default class CharacterSlider {
  constructor() {
    this.setup();
  }
  setup() {
    const elm = document.querySelector('.characterSlider');
    const thumbelm = document.querySelector('.characterSliderThumb');
    if (elm) {
      const splide = new Splide(elm, {
        rewind: true,
        speed: 600,
        arrows: true,
        type: 'fade',
        heightRatio: 0.88,
        pagination: false,
        cover: true
      });
      const thumbs = new Splide(thumbelm, {
        rewind: true,
        fixedWidth: '5.6788vw',
        fixedHeight: '6.5049vw',
        isNavigation: true,
        arrows: false,
        gap: '0.8vw',
        pagination: false,
        cover: true,
        drag: true,
        breakpoints: {
          768: {
            fixedWidth: '23.8666vw',
            fixedHeight: '27.3333vw',
            gap: '3vw',
            drag: false
          }
        }
      });

      splide.sync(thumbs);
      splide.mount({URLHash});
      thumbs.mount({URLHash});
    }
  }
}
