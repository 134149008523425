import 'svgxuse';
import 'focus-visible';
import ObjectFit from './modules/ObjectFit';
import MatchMedia from './modules/MatchMedia';
import AddNoopener from './modules/AddNoOpener';
import Smoothscroll from './modules/SmoothScroll';
import ObserveTarget from './modules/ObserveTarget';
import Hamburger from './modules/Hamburger';
import Accordion from './modules/Accordion';
import Tab from './modules/Tab';
import Modal from './modules/Modal';
import ScrollEvent from './modules/ScrollEvent';
import Loading from './modules/Loading';
import CharacterSlider from './modules/CharacterSlider';
import VideoModal from './modules/VideoModal';
import StorySlider from './modules/StorySlider';

class Index {
  constructor() {
    new ObjectFit();
    new MatchMedia();
    new AddNoopener();
    new Smoothscroll();
    new ObserveTarget();
    new Hamburger();
    new Accordion();
    new Tab();
    new Modal();
    new ScrollEvent();
    new Loading();
    new CharacterSlider();
    new VideoModal();
    new StorySlider();
  }
}

export default new Index();
